import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` added new functionality to activity metric reports that enables enterprises to compare metrics over different time intervals.`}</p>
    <p>{`Want to see how this year’s holiday season compared to last? or compare May’s data vs January’s? Dashbot’s new comparison feature is for you.`}</p>
    <p>{`Simply, select Compare to past from a report, choose a corresponding date range, and historical data will appear along side the currently selected data.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/06/25122200/comparsion-sessions.png",
        "alt": null
      }}></img>{` This new feature is currently available on our Users, Sessions, Message Count, Lifetime Users, and Sentiment reports.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is an analytics platform for conversational interfaces that enables enterprises to increase engagement, satisfaction, and conversion through actionable insights and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we provide chatbot specific analytics including NLP response effectiveness, sentiment analysis, conversation flows, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our NLP Optimization tools, Phrase Clustering, NLP Assessments, and Live Person Take Over of chat sessions.`}</p>
    <p>{`We support Alexa, Google Assistant, Web Chat, SMS, Facebook Messenger, Slack, Twitter, Bixby, and any other conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      